import { PERMISSIONS_URL, PERMISSIONS_API_TOKEN, authToken } from '../env'
import axios from '../_client'
import { EventBus } from '@/event-bus'

const permissionsAxiosInstance = axios.create({
  baseURL: `${PERMISSIONS_URL}/api/v1`,
  headers: {
    'api-token': PERMISSIONS_API_TOKEN
  }
})
permissionsAxiosInstance.interceptors.response.use(function (response) {
  if (response.data.redirect_to) {
    location.href = response.data.redirect_to
  }
  if (response.data.easter_egg) {
    EventBus.$emit('show-easter-egg', response.data.easter_egg)
  }
  return response
}, function (error) {
  if (error.response.status !== 404) {
    EventBus.$emit('show-error-modal', error)
  } else if (error.response.status === 404) {
    EventBus.$emit('show-error-snackbar', error)
  }
  return Promise.reject(error)
})
export const PermissionsAPI = {
  updatePermissionById (permissionId, data) {
    return permissionsAxiosInstance({
      method: 'patch',
      url: `/permissions/${permissionId}`,
      headers: {
        'auth-token': authToken()
      },
      data
    })
  },
  create (data) {
    return permissionsAxiosInstance({
      method: 'post',
      url: `/permissions`,
      headers: {
        'auth-token': authToken()
      },
      data
    })
  },
  delete (id) {
    return permissionsAxiosInstance({
      method: 'delete',
      url: `/permissions/${id}`,
      headers: {
        'auth-token': authToken()
      }
    })
  }
}

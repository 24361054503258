<template>
  <div>
    <InputText
      @blur="updateSelectedResource"
      noPadY="true"
      classApplied="subtitle-1"
      v-model="resource.display_name"
    />
    <InputText
      @blur="updateSelectedResource"
      noPadY="true"
      classApplied="caption font-weight-medium darkGrey--text"
      v-model="resource.description"
    />
    <InputText
      @blur="updateSelectedResource"
      noPadY="true"
      classApplied="body-2"
      v-model="resource.role_permission_limit"
    />
    <br />
    <v-list color="#FAFAFA" v-if="resource.permissions.length>0">
      <span v-for="permission in resource.permissions" :key="permission.id">
        <v-list-item class="pa-0" two-line>
          <v-list-item-content class="pa-0">
            <v-list-item-title>
              <InputText
                noPadY="true"
                classApplied="subtitle-1 ma-0 pt-3 mb-3 font-weight-medium"
                v-model="permission.display_name"
                @blur="updatePermission(permission)"
              />
            </v-list-item-title>
            <v-list-item-subtitle>
              <InputText
                @blur="updatePermission(permission)"
                noPadY="true"
                classApplied="body-2 ma-0 py-3"
                v-model="permission.description"
              />
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="deletePermission(permission)">
              <v-icon small color="grey lighten-1">delete</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
      </span>
    </v-list>
    <div v-else>
      <p class="my-10 py-10 text-center darkGrey--text text--lighten-3">
        No permissions created yet
        <br />Create your first permission!
      </p>
    </div>
    <!-- Create Permission -->
    <v-dialog
      v-model="createNew.dialog"
      scrollable
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title>
          <span class="pr-2">
            <v-icon small>mdi-lock-open</v-icon>
          </span>
          Create a permission
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="createNew.display_name"
            flat
            name="Resource Display Name"
            label="Permission Display Name"
          ></v-text-field>
          <v-text-field v-model="createNew.name" flat name="Resource Name" label="Permission Name"></v-text-field>
          <v-text-field
            v-model="createNew.description"
            flat
            name="Description"
            label="Add a description"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="createNew.dialog = false" color="grey" text class="text-capitalize">Cancel</v-btn>
          <v-btn
            elevation="0"
            @click="createPermission"
            color="primary"
            class="text-capitalize"
          >Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Delete Modal -->
    <DeleteModal
      :title="deleteDialog.title"
      :status="deleteDialog.status"
      :callback="deleteDialog.callback"
      @success="removeDeletedPermission"
      @close="deleteDialog.status = false"
    />
  </div>
</template>

<script>
import { ApplicationsAPI } from '@/clients/permissions/apps'
import { PermissionsAPI } from '@/clients/permissions/permissions'
export default {
  props: {
    resource: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      deleteDialog: {
        status: false,
        title: '',
        description: null,
        callback: null,
        permissionId: null
      },
      createNew: {
        dialog: false,
        name: '',
        display_name: '',
        description: ''
      }
    }
  },
  components: {
    InputText: () => import('@/components/custom-components/InputText'),
    DeleteModal: () => import('@/components/custom-components/DeleteModal')

  },
  methods: {
    openDialog() {
      this.createNew = true
    },
    updateSelectedResource() {
      ApplicationsAPI.updateAppResourceById(this.$route.params['id'], this.resource.id, this.resource)
    },
    updatePermission(permission) {
      permission.name = permission.display_name
      PermissionsAPI.updatePermissionById(permission.id, permission)
    },
    async createPermission() {
      this.spinner(true)
      this.createNew.app_resource_id = this.resource.id
      let response
      try {
        response = await PermissionsAPI.create(this.createNew)
      } catch (error) {
        this.spinner(false)
        return console.log(error)
      }
      this.resource.permissions.push(response.data.payload)
      this.createNew = {
        dialog: false,
        name: '',
        display_name: '',
        description: ''
      }
      this.spinner(false)
    },
    deletePermission(permission) {
      this.deleteDialog.status = true
      this.deleteDialog.title = `Are you sure you want to delete ${permission.display_name}?`
      this.deleteDialog.callback = () => PermissionsAPI.delete(permission.id)
      this.deleteDialog.permissionId = permission.id
    },
    removeDeletedPermission() {
      const index = this.resource.permissions.findIndex(i => i.id === this.deleteDialog.permissionId)
      if (index > -1) {
        this.resource.permissions.splice(index, 1)
      }
      this.deleteDialog.status = false
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
